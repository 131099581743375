import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "gatsby";
import Logo from '../../assets/img/logo-bueno.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-md-4 col-lg-2 order-3 order-lg-0" >
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to='/'>

                                    <img src={Logo} alt="Logo-footer" className="Logo-footer" style={{height:'6em'}}/>

                                </Link>
                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Banco de Alimentos de México.  Todos los derechos reservados. 
                                    <br/><Link to='/aviso-de-privacidad'><strong> Leer aviso de privacidad</strong></Link>
                                </Text>

                            </div>
                        </div>
                    </div>

                    

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Síguenos">
                            <List classes="widget-list" >
                                <LI><a className="social-list-item" style={{color:'#151515'}} href='https://www.facebook.com/bamxmerida/' target={'blank'} rel={"noopener nofererrer"}><i style={{transform:'scale(1.5)'}} class="fa fa-facebook"></i></a></LI>
                                <LI><a className="social-list-item" style={{color:'#151515'}} href="https://www.instagram.com/bamx_merida/" target={'blank'} rel={"noopener nofererrer"}><i style={{transform:'scale(1.5)'}} class="fa fa-instagram"></i></a></LI>
                                <LI><a className="social-list-item" style={{color:'#151515'}} href="https://youtube.com/channel/UC_mxWjqzgm3aS0TNccIN1CQ" target={'blank'} rel={"noopener nofererrer"}><i style={{transform:'scale(1.5)'}} class="fa fa-youtube"></i></a></LI>
                                <LI><a className="social-list-item" style={{color:'#151515'}} href="https://twitter.com/bamxmerida?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target={'blank'} rel={"noopener nofererrer"}><i style={{transform:'scale(1.5)'}} class="fa fa-twitter"></i></a></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contacto">
                            <List classes="widget-list">
                                <LI><address>
                                Calle 60 #427 entre 69 y 71, Colonia Mil Piedras.
CP 97298.<br/> Mérida, Yuc. México.</address></LI>
                                <LI class="footer-item"><a style={{color:'#151515', fontWeight:'500'}} href="tel:9994422060" className="tel-no">999 442 2060</a></LI>
                                <LI class="footer-item"><a style={{color:'#151515', fontWeight:'500'}} href="https://m.me/bamxmerida/" target={'blank'} rel={"noopener nofererrer"}>Facebook Messenger</a></LI>
                                <LI><a style={{color:'#151515'}} href="https://www.google.com.mx/maps/dir/21.0336864,-89.6157462/banco+de+alimentos+merida/@20.9707088,-89.7019313,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8f5670b30f3fa1f9:0x2892d360d22db2f4!2m2!1d-89.6387815!2d20.9037802" target={'blank'} rel={"noopener nofererrer"}>Ubicacion</a></LI>
                                <LI class="footer-item"><Link style={{color:'#151515', fontWeight:'500'}} to='/Contact'>Correo electrónico</Link></LI>
                            </List>

                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;