import React from 'react';
import parse from 'html-react-parser'

import CallToActionData from '../../data/CallToAction/call-to-action'
import {Link} from "gatsby";

function CallToAction() {

  
    return (
        <>
        <div style={{ backgroundColor:'#f5a000'}} className="call-top-action-wrap sp-y">
            <div className="container">
                <div className="footer-top-content">
                    <div id="cta-container"  style={{width:'100%',paddingTop:'5px',display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}} >
                        <div className="col-md-8 col-lg-6" style={{marginBottom:'1%'}}>
                            <h2 style={{margintTop:'0px !important', textAlign:'center'}}>¿Listo para ayudar?</h2>
                            <p></p>
                        </div>
                        <div className="col-md-4 col-lg-6 text-md-center mt-sm-25" style={{marginBottom:'2.5%', display:'flex', flexDirection:'row', justifyContent:'center'}}>
                            <a target={'_blank'} rel={'noopener noreferrer'} href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YXBYS99ELREUW&source=url'><div style={{backgroundColor:'#d33', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s'}} className="btn btn-brand">Donar ahora</div></a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    );
}

export default CallToAction;