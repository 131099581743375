import React, {Component} from 'react';
import logo from '../../assets/img/logo-bueno.png'
import {Link} from 'gatsby'

class Logo extends Component {
    render() {
        return (
            <div className="logo-area" style={{paddingTop:'5%'}}>
                <Link href="/"><img class="logo-image" src={logo} alt="Businex-Logo" /></Link>
            </div>
        );
    }
}

export default Logo;