import React from 'react';
import NavbarItem from './NavbarItem'
import {Link} from 'gatsby'
import List from '../../UI/List'
import LI from '../../UI/List/Item'

function Navbar(props) {
    return (
        <ul className="main-menu nav" >
            <NavbarItem />
            <li key='07'>
                <a style={{color:'#c92b28', fontWeight:'700'}} target={'_blank'} rel={'noopener noreferrer'} href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YXBYS99ELREUW&source=url'>Donar</a>
            </li>
        </ul>
    );
}

export default Navbar;