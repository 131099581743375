import React from 'react';
import {Link} from "gatsby";
import navbarData from "../../../data/Navbar/navbar";

function NavbarItem(props) {
    return(
        navbarData.map(item=>(
            
            <li key={item.id}
                className={item.subMenu || item.megaMenu ? 'has-submenu' : ''}
            >
                <Link to={`${item.link}`}>{item.title}</Link>
                
                
            </li>
        ))
        )
}

export default NavbarItem